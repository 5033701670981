import { FC, useState } from 'react'

import { FunnelIcon } from '@heroicons/react/24/outline'

import Button from 'src/components/Library/Button/Button'
import { SearchField } from 'src/components/Library/SearchField/SearchField'

import { DocumentStorageAdminBarProps } from '../../DocumentStorageTypes'

const DocumentFolderAdminBar: FC<DocumentStorageAdminBarProps> = ({
  setAddFolderModalOpen,
}) => {
  const [value, setValue] = useState('')

  return (
    <div className="bg-white p-4">
      <div className="flex gap-2 flex-wrap justify-between flex-col sm:flex-row w-full">
        <div className="flex flex-wrap grow gap-2">
          <SearchField
            className="!w-auto grow sm:grow-0"
            value={value}
            onChange={setValue}
          />
          <Button
            className="text-gray-400 border-gray-300 grow sm:grow-0"
            size="small"
            variant="outlined"
            fullWidth={false}
            startIcon={<FunnelIcon className="w-4 h-4" />}
          >
            Filter
          </Button>
        </div>
        <div className="flex flex-wrap gap-2">
          <Button
            size="small"
            className="bg-indigo-100 hover:bg-indigo-200  focus:border-indigo-600 focus:border text-indigo-600 grow sm:grow-0"
            fullWidth={false}
            onClick={() => {
              setAddFolderModalOpen(true)
            }}
          >
            Add Folder
          </Button>
          <Button size="small" className="grow sm:grow-0" fullWidth={false}>
            Upload File
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DocumentFolderAdminBar
