import { FC, useState } from 'react'

import DocumentSidebar from '../../DocumentSidebar/DocumentSidebar'
import { DocumentStorageFoldersLayoutProps } from '../../DocumentStorageTypes'
import DocumentAddFolderModal from '../DocumentAddFolderModal/DocumentAddFolderModal'
import DocumentFolderAdminBar from '../DocumentFolderAdminBar/DocumentFolderAdminBar'
import DocumentFolderDataGrid from '../DocumentFolderDataGrid/DocumentFolderDataGrid'

const DocumentFoldersLayout: FC<DocumentStorageFoldersLayoutProps> = ({
  documentFolders,
  refetch,
}) => {
  const [addFolderModalOpen, setAddFolderModalOpen] = useState(false)

  return (
    <div className="w-full">
      <DocumentFolderAdminBar setAddFolderModalOpen={setAddFolderModalOpen} />
      <DocumentFolderDataGrid />

      <DocumentSidebar />

      <DocumentAddFolderModal
        documentFolders={documentFolders}
        addFolderModalOpen={addFolderModalOpen}
        setAddFolderModalOpen={setAddFolderModalOpen}
        refetch={refetch}
      />

      <ul>
        {documentFolders.map((item) => {
          return <li key={item.id}>{JSON.stringify(item)}</li>
        })}
      </ul>
    </div>
  )
}

export default DocumentFoldersLayout
