import React, { FC, useEffect, useState } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import TextField from '@mui/material/TextField'
import { DocumentFolder } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import Modal from 'src/components/Modal/Modal'

import { CREATE_DOCUMENT_STORAGE_FOLDER } from '../../DocumentStorageQueries'
import { DocumentStorageAddFolderModalProps } from '../../DocumentStorageTypes'

const DocumentAddFolderModal: FC<DocumentStorageAddFolderModalProps> = ({
  documentFolders,
  addFolderModalOpen,
  setAddFolderModalOpen,
  refetch,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [folderName, setFolderName] = useState('')

  const [selectedParentFolder, setSelectedParentFolder] =
    useState<DocumentFolder>(null)

  const [createDocumentStorageFolderMutation] = useMutation(
    CREATE_DOCUMENT_STORAGE_FOLDER,
    {
      onCompleted: async () => {
        toast.success('Folder Created', {
          duration: 1000,
          className: 'flex-column',
        })

        await refetch()
        closeAddFolderModal()
      },
      onError: (error) => {
        toast.success(
          `There was an error creating your folder. /n ${error.message}}`,
          {
            duration: 2000,
            className: 'flex-column',
          },
        )
        setIsLoading(false)
      },
    },
  )

  // Create new folder
  const createFolder = async () => {
    setIsLoading(true)

    await createDocumentStorageFolderMutation({
      variables: {
        input: {
          name: folderName,
          parentDocumentFolderId: selectedParentFolder?.id,
        },
      },
    })
  }

  // Reset the form fields
  const resetFormFields = () => {
    setFolderName('')
    setSelectedParentFolder(null)
  }

  // Close the modal
  const closeAddFolderModal = () => {
    resetFormFields()
    setAddFolderModalOpen(false)
  }

  // Reset loading on modal close
  useEffect(() => {
    setIsLoading(false)
  }, [addFolderModalOpen])

  return (
    <Modal
      open={addFolderModalOpen}
      closeButtonVisible={false}
      className="max-w-2xl !p-0"
      dialogClassName="!bg-white !p-0"
      onClose={() => {}}
    >
      <form
        id="createDocumentStorageFolder"
        onSubmit={async (e) => {
          e.preventDefault()
          await createFolder()
        }}
      >
        <div id="createCardModal">
          <div className="flex items-center justify-between px-6 py-4 pt-3">
            <h3 className="text-lg leading-6 mb-0 font-medium text-gray-900">
              Add Folder
            </h3>
            <Button
              disabled={isLoading}
              fullWidth={false}
              variant="text"
              size="small"
              className="py-2"
              onClick={() => {
                closeAddFolderModal()
              }}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          </div>

          <div className="p-6">
            <FormInputRow label="Folder Name">
              <TextField
                id="createDocumentStorageFolderName"
                fullWidth={true}
                size="small"
                value={folderName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFolderName(event.target.value)
                }}
                required
              />
            </FormInputRow>
            <FormInputRow label="Parent Folder">
              <Autocomplete
                id="createDocumentStorageFolderParentFolder"
                size="small"
                options={documentFolders}
                getOptionLabel={(option: DocumentFolder) => option.name}
                renderOption={(props, option: DocumentFolder) => {
                  return <li {...props}>{option.name}</li>
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Parent Folder" />
                )}
                value={selectedParentFolder}
                onChange={(_, newValue: DocumentFolder) => {
                  setSelectedParentFolder(newValue)
                }}
                isOptionEqualToValue={(
                  option: DocumentFolder,
                  value: DocumentFolder,
                ) => {
                  return option.id === value?.id
                }}
              />
            </FormInputRow>
          </div>
        </div>

        <div className="bg-gray-100 px-6 py-4 flex flex-row-reverse items-center justify-between">
          <div className="flex gap-2">
            <Button
              disabled={isLoading}
              fullWidth={false}
              variant="text"
              size="small"
              className="px-4"
              onClick={() => {
                closeAddFolderModal()
              }}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              type="submit"
              form="createDocumentStorageFolder"
              fullWidth={false}
              className="py-2"
              size="small"
            >
              Create Folder
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default DocumentAddFolderModal
