import { FC } from 'react'

import {
  DocumentFoldersQuery,
  DocumentFoldersQueryVariables,
} from 'types/graphql'

import { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import { DocumentStorageFolderCellProps } from '../../DocumentStorageTypes'
import DocumentFoldersLayout from '../DocumentFoldersLayout/DocumentFoldersLayout'

export const QUERY = gql`
  query DocumentFoldersQuery {
    documentFolders {
      id
      name
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

type CellProps = FC<
  CellSuccessProps<DocumentFoldersQuery, DocumentFoldersQueryVariables> & {
    documentId: number
  }
>

export const Empty: CellProps = ({ documentId, queryResult: { refetch } }) => (
  <DocumentFoldersLayout
    documentFolders={[]}
    documentId={documentId}
    refetch={refetch}
  />
)

export const Success: CellProps = ({
  documentFolders,
  documentId,
  queryResult: { refetch },
}) => {
  return (
    <DocumentFoldersLayout
      documentFolders={documentFolders}
      documentId={documentId}
      refetch={refetch}
    />
  )
}
