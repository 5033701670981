import { FC } from 'react'

import { FEATURE } from 'api/src/common/enums'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import DocumentFoldersCell from 'src/components/DocumentStorage/DocumentFolders/DocumentFoldersCell'
import { DocumentStoragePageProps } from 'src/components/DocumentStorage/DocumentStorageTypes'
import PageHeader from 'src/components/PageHeader/PageHeader'
import useFeature from 'src/lib/hooks/useFeature'

const DocumentStoragePage: FC<DocumentStoragePageProps> = ({ documentId }) => {
  // Get Document Storage Status
  const documentStorageFeatureFlag = useFeature(FEATURE.DOCUMENT_STORAGE)

  // Navigate away when permission denied
  if (!documentStorageFeatureFlag) {
    navigate(routes.home())
  }

  return (
    <>
      <Metadata title="Document Storage" description="Document Storage" />
      <PageHeader title="Document Storage" />
      <DocumentFoldersCell documentId={documentId} />
    </>
  )
}

export default DocumentStoragePage
