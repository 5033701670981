// CREATE GOAL TEMPLATE ITEMS
export const CREATE_DOCUMENT_STORAGE_FOLDER = gql`
  mutation CreateDocumentStorageFolderMutation(
    $input: CreateDocumentFolderInput!
  ) {
    createDocumentFolder(input: $input) {
      id
      name
    }
  }
`
